<template>
  <div>
    <div class="subHeader base noFlex qnaManagement">
      <div class="module-header">
        <h2>문의사항 관리</h2>
      </div>

      <div class="module-config">
        <div class="wtBox config-list">
          <header class="flexL">
            <h3>
              {{ category }}<span>{{ title }}</span>
            </h3>
            <!-- <span class="rubberBand"></span> -->
          </header>

          <p>
            <span>{{ username }}</span>
            <span>{{ moment(createdAt).format("YYYY.MM.DD HH:mm") }}</span>
          </p>

          <article>
            <pre>{{ content }}</pre>
          </article>
        </div>

        <div class="wtBox config-list">
          <header class="flexL">
            <h3>답변하기</h3>
            <!-- <span class="rubberBand"></span> -->
          </header>

          <!-- <p><span>2021. 12. 15 18:20</span><span>홍길동</span></p> -->

          <textarea cols="30" rows="10" v-model="replys"></textarea>
          <div class="buttonWrap">
            <router-link to="/admin/qna"
              ><button class="right line">목록</button></router-link
            ><button @click="submit" class="pointOut">
              {{ status ? "답변수정" : "답변작성" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-show="total > 1" class="module-paging">
      <div class="inner">
        <ul class="skip prev">
          <li>
            <a href="#" @click="handlePaging(currentPage - 1)">
              <i class="material-icons">chevron_left</i>
            </a>
          </li>
        </ul>

        <ul class="paging">
          <li v-for="(page, i) in total" :key="i">
            <a
              href="#"
              :class="{ active: currentPage == i + 1 }"
              @click="handlePaging(page)"
              >{{ page }}</a
            >
          </li>
        </ul>

        <ul class="skip next">
          <li>
            <a href="#" @click="handlePaging(currentPage + 1)">
              <i class="material-icons">chevron_right</i>
            </a>
          </li>
        </ul>
      </div>
    </div> -->
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import moment from "moment";

import { fetchQnaDetails, updateQnaReply, createQnaReply } from "@/api/member";
export default {
  data() {
    return {
      moment: moment,
      qnaId: "",
      title: "",
      content: "",
      createdAt: "",
      category: "",
      username: "",
      replys: "",
      status: "",
    };
  },
  mounted() {
    this.qnaId = this.$route.query.id;
    this.getQna();
  },
  methods: {
    getQna() {
      fetchQnaDetails(this.qnaId).then((res) => {
        if (res.data.status == 200) {
          this.title = res.data.data.title;
          this.content = res.data.data.content;
          this.createdAt = res.data.data.createdAt;
          this.category = res.data.data.category;
          this.username = res.data.data.author.username;
          if (res.data.data.replys && res.data.data.replys[0]) {
            this.replys = res.data.data.replys[0].contents;
          }
          this.status = res.data.data.status;
        } else {
          return alert(res.data.message);
        }
      });
    },
    submit() {
      if (this.replys == "") {
        return alert("내용을 입력해 주세요.");
      }
      let data = {
        postId: this.qnaId,
        replys: this.replys,
      };
      if (this.status) {
        updateQnaReply(data).then((res) => {
          if (res.data.status == 200) {
            this.$router.push("/admin/qna");
            return alert("답변이 수정 되었습니다.");
          } else {
            return alert(res.data.message);
          }
        });
      } else {
        createQnaReply(this.qnaId, data).then((res) => {
          if (res.data.status == 200) {
            this.$router.push("/admin/qna");
            return alert("답변이 등록 되었습니다.");
          } else {
            return alert(res.data.message);
          }
        });
      }
    },
  },
};
</script>
